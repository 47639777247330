import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/sprayers/backpack-sprayers/',
    imageId: 'backpackSprayersImage',
    name: 'Backpack Sprayers',
    alt: 'STIHL Backpack Sprayers',
  },
]

const SprayersPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Sprayers | Hutson Inc</title>
        <meta
          name='description'
          content='For spraying jobs that require great power and control, look for a STIHL sprayer. Perfect for gardens, home use, or professional applications.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Sprayers',
                'item': 'https://www.hutsoninc.com/stihl/sprayers/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Sprayers' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Sprayer' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query sprayersStihlQuery($category: String = "sprayers") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/sprayers/sprayers-bg.jpg" }) {
      ...FullWidthImage
    }
    backpackSprayersImage: file(relativePath: { eq: "stihl/sprayers/backpack-sprayers.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default SprayersPage
